@tailwind base;
@tailwind components;
@tailwind utilities;

body {
    background-color: rgb(241 245 249);
    overflow-y: auto;
    height: 100vh;
}

/* .App-logo {
    animation: App-logo-spin 20s infinite linear;
} */

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}

/* Scrollbar */
/* ::-webkit-scrollbar{
    width: 8px;
}

::-webkit-scrollbar-track{
    background: #c6d0dd;
}

::-webkit-scrollbar-thumb{
    background: #9a9ca5;
    border-radius: 6px;
} */
/*  */

/* PARA LA BARRA */
/* Estilo para navegadores WebKit (Chrome, Safari) */
::-webkit-scrollbar {
    width: 8px; /* Ancho de la barra de desplazamiento */
    height: 8px; /* Alto de la barra de desplazamiento horizontal */
}

::-webkit-scrollbar-track {
    background: #f0f0f0; /* Color del fondo de la pista */
}

::-webkit-scrollbar-thumb {
    background: #cfcece; /* Color de la barra de desplazamiento */
    border-radius: 10px; /* Bordes redondeados para el pulgar */
}

/* Opcional: agregar un efecto en hover */
::-webkit-scrollbar-thumb:hover {
    background: #a0a0a0; /* Color del pulgar al pasar el mouse */
}

.dropdown-menu {
    position: absolute;
    top: 80px;
    right: 35px;
    background-color: rgb(255, 255, 255);
    border-radius: 8px;
    padding: 10px 20px;
    width: 200px;

    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.dropdown-menu.activo {
    opacity: 1;
    visibility: visible;
    transform: translateY(0);
    transition: 500ms ease;
}

.dropdown-menu.inactive {
    opacity: 0;
    visibility: hidden;
    transform: translateY(-20px);
    transition: 500ms ease;
}

.dropdown-menu::before {
    content: '';
    position: absolute;
    top: -5px;
    right: 20px;
    height: 20px;
    width: 20px;
    transform: rotate(45deg);
    background-color: rgb(255, 255, 255);
}

.dropdown-menu ul li:nth-child(2) {
    padding: 5px 0px;
    border-top: 1px solid rgb(212, 212, 212);
}

.dropdown-menu ul li:hover a {
    cursor: pointer;
    background-color: rgb(235, 235, 235);
}

.dropdownItem {
    display: flex;
    margin: 10px auto;
}

.dropdown-menu ul li a {
    display: block;
    width: 100%;
}

.dropdownItem a {
    border-radius: 8px;
    padding: 8px 5px;
    transition: 500ms;
}

.ps-submenu-content {
    background-color: rgb(226, 243, 253) !important;
}

.ps-submenu-content ul li a {
    font-size: 14px !important;
    margin-left: -20px !important;
}

.ps-submenu-content ul li a:hover {
    background-color: rgb(181, 228, 255) !important;
}

.dropdown-user-icono {
    transform: rotate(-180deg);
}

.dropdown-user-icono.rotado {
    transform: rotate(180deg);
}

.navbar-link {
    background-color: white !important;
    color: rgb(75, 85, 99) !important;
}

.navbar-link:hover {
    background-color: rgb(243, 243, 243) !important;
}

.navbar-link.active {
    background-color: #dd8022 !important;
    color: white !important;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
}

.navbar-link.active:hover {
    background-color: #ca7521 !important;
    color: white !important;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
}

.navbar-sublink {
    background-color: rgb(226, 243, 253);
    color: rgb(75, 85, 99) !important;
}

.navbar-sublink.active {
    background-color: #dd8022 !important;
    color: white !important;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
}

.navbar-sublink.active:hover {
    background-color: #ca7521 !important;
    color: white !important;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
}

/* .active {
    background-color: #35aa62 !important;
    color: white !important;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
} */

/* .active:hover {
    color: black !important;
} */

/* Input de codigo de validación */
.character {
    border: 1px solid #B5B5B5;
    font-size: 28px;
    border-radius: 8px;

    /* light theme */
    color: #272729;
    background-color: #F9F9F9;
    height: 54px;
    margin-top: -7px;

    color: #D27313;
    font-weight: 400;
}

/* MODALES */

.modal-content {
    max-width: 90%;
    width: 600px;
    margin: 0 20px;
}

/* React Calendar */
/* .rbc-toolbar button:last-of-type {
    display: none;
} */

/* .css-b62m3t-container, .css-qbdosj-Input input{
    z-index: -99999999999999999999999999999999999;
} */

.soporte_card{
    box-shadow: 0 6px 12px rgba(0, 0, 0, .175);
}

.input_mensaje_soporte{
    width: 360px !important;
}

*::selection {
    background-color: #EB8927;
    color: white;
}

@media (max-width: 963px){
    .input_mensaje_soporte{
        width: 100% !important;
    }
}

@media (max-width: 600px) {
    .modal-content {
        max-width: 100%;
        width: auto;
        margin: 0;

        overflow-x: auto;
        min-width: 300px;
    }
}